<template>
 <!-- 开发票选中的订单列表页面 -->
 <div class="applyInvoiecSlelctList" >
	
	<div class="list" v-if="list" >
		<div class="item" v-for="item in list" >
			<div class="item-val">
				<div>
					<span>{{item.startConsultTime}} </span>
					<span class="item-val-name">{{item.chatRoomName}}</span>
				</div>
				<div>
					<span>医生：{{item.doctorName}}（{{item.serverOrganization}}）  </span>
				</div>
				<div class="money-view" >
					<span class="money" >{{getServerPrice(item.serverPrice)}}元</span>
				</div>
			</div>
			
		</div>
	</div>
 </div>
</template>

<script>
import { NavBar } from "vant";

export default {
  name: "applyInvoiecSlelctList",
  components: {
	  [NavBar.name]:NavBar
  },
  provide() {
    return {};
  },
  props:{
	  show:{},
	  list:{}
  },
  data() {
    return {};
  },
  mixins: [],
  watch: {},
  created() {},
  mounted() {},
  methods: {
	  // 返回价格
	  getServerPrice(n){
	  if(!n){
	  	return n
	  }
	  n=n/100;
	  return Number(n.toFixed(2))
	  },
  },
  beforeUnmount() {},
  // beforeRouteLeave(to,from,next){},
};
</script>
<style scoped lang="scss">
.applyInvoiecSlelctList{
	height:80vh;
	overflow: auto;
}
.list {
		
		box-sizing: border-box;
		margin: 10px 0;
		
		.item{
			padding: 10px;
			border-bottom: 1px solid #ccc;
			font-size: 14px;
			line-height: 28px;
			.money-view{
				text-align: right;
				.money{
					font-weight: 600;
					color: #00a8ff;
				}
			}
			.item-val{}
			.item-val-name{
				margin-left: 6px;
				font-weight: 600;
			}
			
		}
	}
</style>
