<template>
 <!-- 发票抬头列表页 -->
 <div class="invoiceTitle" >
	 <van-nav-bar
	   title="开具发票"
	   left-arrow
	   left-text="关闭"
	   right-text="选择抬头"
	   @click-left="$emit('update:show',false)"
	    @click-right="changeTitleList"
	 >
	 </van-nav-bar>
	<!-- right-text="选择抬头" -->
	<van-form style="height:calc(100% - 50px);overflow: auto; " @submit="onSubmit">
	  <van-cell-group>
		  <div class="huiTitle">发票详情</div>
		  <van-field 
		   :required="true"
		    :rules="[{ required: true, message: '请选择抬头类型' }]"
		   label="抬头类型">
		    <template #input>
		      <van-radio-group v-model="formObj.riseType" direction="horizontal">
		        <van-radio :name="1">企业单位</van-radio>
		        <van-radio :name="2">个人/非企业单位</van-radio>
		      </van-radio-group>
		    </template>
		  </van-field>
		  
		  
	    <template v-if="formObj.riseType==1" key="form_obj_a" >
			<van-field
			  v-model="formObj.riseName"
			  label="公司名称"
			  placeholder="公司名称"
			   :required="true"
			  :rules="[{ required: true, message: '请填写公司名称' }]"
			/>
			<van-field
			  v-model="formObj.corporateNumber"
			  label="公司税号"
			  placeholder="公司税号"
			   :required="true"
			  :rules="[{ required: true, message: '请填写公司税号' }]"
			/>
			<van-field
			  v-model="formObj.corporateAddress"
			  label="公司地址"
			  placeholder="公司地址"
			/>
			<van-field
			  v-model="formObj.corporatePhone"
			  label="公司电话"
			  placeholder="公司电话"
			/>
			<van-field
			  v-model="formObj.corporateBank"
			  label="公司开户行"
			  placeholder="公司开户行"
			/>
			<van-field
			  v-model="formObj.corporateAccount"
			  label="开户行账号"
			  placeholder="开户行账号"
			/>
			
		</template>
		
		<template v-else-if="formObj.riseType==2" key="form_obj_b" >
			<van-field
			  v-model="formObj.riseName"
			  label="姓名"
			  placeholder="姓名"
			   :required="true"
			  :rules="[{ required: true, message: '请填写姓名' }]"
			/>
		</template>
		<van-field
		  v-model="formObj.remark"
		  label="备注"
		  placeholder="备注"
		/>
		<div class="huiTitle mtop10 listView">
			<span>
				<div class="leftName" >总金额</div>
				<span>{{checkedObj.money||""}}元</span>
			</span>
			<span>
				<span>共{{(checkedObj.list||[]).length}}张</span>
				<span @click="showList" >查看详情</span>
			</span>
		</div>
		<div class="huiTitle mtop10">
			接收方式
		</div>
		<van-field
		class="mtop10"
		  v-model="formObj.email"
		  label="电子邮箱"
		  placeholder="电子邮箱"
		   :required="true"
		  :rules="rules.email"
		/>
		<van-field
		  v-model="formObj.phone"
		  label="手机号"
		  placeholder="手机号"
		   :required="true"
		  :rules="rules.phone"
		/>
	  </van-cell-group>
	  <div style="padding: 16px;">
	    <van-button round block type="primary" native-type="submit">
	      提交
	    </van-button>
	  </div>
	</van-form>
	 <!-- 抬头列表弹框 -->
	 <van-popup v-model:show="isShow.titleList" round position="bottom">
	   <van-picker
	     title="请选择发票抬头"
		  :columns-field-names="{text: 'riseName'}"
	     :columns="titleList"
	     @cancel="isShow.titleList = false"
	     @confirm="selectTitle"
	   />
	 </van-popup>
	 <!-- 选中列表弹框 -->
	 <van-popup v-model:show="isShow.list" round position="bottom">
	   <apply-invoiec-slelct-list  v-model:show="isShow.list" v-if="isShow.list" :list="checkedObj.list" ></apply-invoiec-slelct-list>
	 </van-popup>
 </div>
</template>

<script>
import { NavBar,Form,CellGroup,Field,RadioGroup,Radio,Button, Toast,Icon ,Popup,Picker} from "vant";
import applyInvoiecSlelctList from "./applyInvoiecSlelctList.vue";
export default {
  name: "invoice",
  components: {
	  [NavBar.name]:NavBar,
	  [Form.name]:Form,
	  [CellGroup.name]:CellGroup,
	  [Field.name]:Field,
	  [RadioGroup.name]:RadioGroup,
	  [Radio.name]:Radio,
	  [Button.name]:Button,
	  [Icon.name]:Icon,
	  [Popup.name]:Popup,
	  [Picker.name]:Picker,
	  applyInvoiecSlelctList
  },
  provide() {
    return {};
  },
  props:{
	  checkedObj:{
		  default:function(){
			  return {}
		  }
	  },
	  show:{},
  },
  data() {
	  let _this=this;
    return {
		formObj:{
			riseType:1,
		},
		isLoading:{},
		isShow:{},
		titleList:[],
		rules:{
				  email:[
					{ required: true, message: '请填写电子邮箱'},
					{validator:(v)=>{
						 return _this.$myVerify.form_rules.mailbox.pattern.test(v)?true:"请输入正确的电子邮箱"
					},mssage:'请输入正确的电子邮箱'},
					 
				  ],
				  phone: [{ required: true, message: '请填写手机号' },
				  {validator:(v)=>{
						 return _this.$myVerify.form_rules.phone.pattern.test(v)?true:"请输入正确的手机号"
					},mssage:'请输入正确的手机号'} ]
		},
	};
  },
  mixins: [],
  watch: {},
  created() {},
  mounted() {},
  methods: {
	  // 获取信息
	  getInfo(){},
	  // 提交、修改
	  onSubmit(){
		  if(this.isLoading.onSubmit){
			  return;
		  }
		  this.isLoading.onSubmit=true;
		  let formObj={...this.formObj}
		  formObj.patientId= this.$cache.local.getItem("patientId");
		  // assistantOrderId
		  // 
		  let ids=[];
		  for(let i in this.checkedObj.list){
			 ids.push( this.checkedObj.list[i].id)
		  }
		  formObj.assistantOrderIds=ids;
		  // 订单id没有发送
		  if(formObj.riseType==2){
			  delete formObj.corporateAccount;
			  delete formObj.corporateAddress;
			  delete formObj.corporateBank;
			  delete formObj.corporateNumber;
			  delete formObj.corporatePhone;
		  }
		  // console.log("提交",formObj);
		  this.$http.request("invoiceSaveInvoice",formObj)
		  .then((res)=>{
			   Toast({
			  type:"success",
			  message:"提交成功",
			  onClose:()=>{
				  this.isLoading.onSubmit=false;
				  this.$emit('update:show',false);
				  this.$emit("onSuccess")
			  }
		  })
		  })
		  .catch(()=>{})
		 
		  
	  },
	  // 选择抬头 按钮
	  changeTitleList(){
		  // 获取抬头列表
		  		this.$http.request("riseList",{
		  			patientId: this.$cache.local.getItem("patientId"),
		  		})
		  		  .then((res)=>{
		  			  this.titleList=res;
					  this.isShow.titleList=true;
		  		  })
		  		  .catch((err)=>{
		  			  console.log(err)
		  		  })
	  },
	  // 选择抬头后回调
	  selectTitle(item){
		  if(!item){
			  this.isShow.titleList=false;
			  return;
		  }
		  // 抬头相关的进行赋值
		  this.formObj.corporateAccount= item.corporateAccount;
		  this.formObj.corporateAddress= item.corporateAddress;
		  this.formObj.corporateBank= item.corporateBank;
		  this.formObj.corporateNumber= item.corporateNumber;
		  this.formObj.corporatePhone= item.corporatePhone;
		  this.formObj.riseName= item.riseName;
		  this.formObj.riseType= item.riseType;
		// 关闭弹框
		this.isShow.titleList=false;
	  },
	  // 显示详情
	  showList(){
		  this.isShow.list=true;
	  },
  },
  beforeUnmount() {},
  // beforeRouteLeave(to,from,next){},
};
</script>
<style scoped lang="scss">
.invoiceTitle{
	height: 100vh;
	.huiTitle{
		background-color: #efefef;
		padding: var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding);
	}
	.mtop10{
		margin-top: 10px;
	}
	.listView{
		.leftName{
			font-size: var(--van-cell-font-size);
			width: var(--van-field-label-width);
			margin-right: var(--van-field-label-margin-right);
			color: var(--van-field-label-color);
		}
		
		display: flex;
		justify-content: space-between;
		>span{
			display: flex;
		}
	}
}
</style>
